/* General input styles */
input[type=text], select, textarea {
  width: 100%;
  padding: 14px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px; /* Increased margin-bottom for more spacing */
  transition: border-color 0.3s, box-shadow 0.3s;
}

input[type=text]:focus, select:focus, textarea:focus {
  border-color: #e43838;
  box-shadow: 0 0 5px rgba(228, 56, 56, 0.5);
  outline: none;
}

/* Style the label to display above the inputs */
label {
  display: block;
  font-size: 18px;
  margin-bottom: 8px;
  opacity: 0;
  animation: fadeIn 0.6s forwards;
}

/* Style the radio button labels */
.radio-label {
  margin-right: 20px;
  font-size: 18px;
}

/* Flex container for radio buttons */
.radio-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Added margin-bottom for more spacing */
}

/* Style the submit button */
input[type=submit] {
  background-color: #e43838;
  color: white;
  padding: 16px 24px;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: 20px auto;
  transition: background-color 0.3s, transform 0.3s;
}

input[type=submit]:hover {
  background-color: #c93030;
  transform: translateY(-3px);
}

/* Style the form container */
form {
  background-color: #fff;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 20px; /* Added margin-bottom for more spacing */
  opacity: 0;
  animation: slideIn 0.6s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  form {
    padding: 30px;
  }
}

@media (max-width: 600px) {
  form {
    padding: 20px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* Remove box shadow on mobile */
    width: 100%;
    margin: 0; /* Remove margin on mobile */
  }

  input[type=submit] {
    width: 100%;
  }

  body {
    background-color: white; /* Set background to white on mobile */
  }

  .radio-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .radio-label {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* Additional margin for rows */
.row {
  margin-bottom: 20px; /* Added margin-bottom for more spacing */
  opacity: 0;
  animation: fadeIn 0.6s forwards;
}

.row:nth-child(even) {
  animation-delay: 0.2s;
}

.row:nth-child(odd) {
  animation-delay: 0.4s;
}

/* Error messages styles */
.error-messages {
  background-color: #ffe5e5;
  border: 1px solid #e43838;
  color: #e43838;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.error {
  margin: 5px 0;
}
