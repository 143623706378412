.greeting-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f2f2f2;
  }
  
  .greeting-content {
    max-width: 800px;
    text-align: left; /* Text linksbündig */
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .greeting-container h1 {
    font-size: 2.2em;
    color: #e43838;
    margin-bottom: 30px;
  }
  
  .greeting-container p {
    font-size: 1.4em;
    line-height: 1.8;
    margin-bottom: 30px;
  }
  
  .to-calculator-button {
    padding: 15px 30px;
    width: 100%;
    background-color: #e43838;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.4em;
    margin-top: 40px; /* Vergrößert den Abstand zum Text */
  }
  
  .to-calculator-button:hover {
    background-color: #c93030;
  }
  
  @media (max-width: 600px) {
    .greeting-content {
      padding: 20px;
    }
  
    .greeting-container h1 {
      font-size: 1.8em;
    }
  
    .greeting-container p {
      font-size: 1.2em;
      margin-bottom: 20px;
    }
  
    .to-calculator-button {
      font-size: 1.2em;
      padding: 10px 20px;
    }
  }
  