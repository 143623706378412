/* Add these styles to Result.css */
.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0%;
}

.chart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 800px;
  height: 300px;
  margin-bottom: 40px;
}

.congratulations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px; /* Optional: adjust margin to position it better */
}
.congratulations-image {
  width: 250px; /* Adjust the size as needed */
  height: auto;
  margin-bottom: 20px;
}
.congratulations-text {
  color: #99d53b;
  text-align: center;
}
h3 {
  width: 100%; /* Ensure full width */
  max-width: 800px; /* Optional max width */
  margin: 20px auto;
  text-align: center;
}
@media (max-width: 600px) {
  .chart-container,
  h3 {
    width: 100%; /* Ensure full width */
  }
  .congratulations-image {
    width: 200px; /* Adjust the size as needed */
  }
}

.MuiChart-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MuiChart-label {
  margin-bottom: 20px;
}

.MuiChart-series {
  margin-top: 20px;
}

.sticky-button {
  position: sticky;
  bottom: 20px;
  margin-right: 90%;
  background-color: #e43838; /* Background color */
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.sticky-button svg {
  width: 50px;
  height: 50px;
  fill: white; /* Arrow color */
}

@media (max-width: 1024px) {
  .sticky-button {
    width: 60px;
    height: 60px;
    bottom: 10vw; /* Adjust this value to move the button closer to the footer on smaller screens */
    left: 20px; /* Align to the left */
  }
  .sticky-button svg {
    width: 26px;
    height: 26px;
  }
}

@media (max-width: 600px) {
  .sticky-button {
    width: 60px;
    height: 60px;
    bottom: 20vw; /* Adjust this value to move the button closer to the footer on smaller screens */
    left: 20px; /* Align to the left */
  }
  .sticky-button svg {
    width: 26px;
    height: 26px;
  }
}

.red-text {
  color: #e43838;
}

.green-text {
  color: #99d53b;
}

