/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f2f2f2;
  font-family: Arial, sans-serif;
  height: 100%;
}

#root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Sticky header styles */
.sticky-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it stays above other content */
}

.header-text {
  color: #e43838;
  margin: 10px 0; /* Add some margin to the text */
  font-size: 24px; /* Adjust size as needed */
  text-align: center; /* Center the text */
}

.background-image {
  width: 100%;
  height: 80px; /* Adjusted to the desired height */
  object-fit: cover; /* Ensure the image covers the whole width */
}

.form-container {
  padding-top: 30px; /* Ensure there's space for the sticky header */
  width: 100%;
  max-width: 800px;
  margin: 0 auto; /* Center the form horizontally */
  flex-grow: 1;
}

.content {
  flex-grow: 1;
  overflow-y: auto; /* Allow vertical scrolling */
  padding-top: 100px; /* Ensure there's space for the sticky header */
  display: flex;
  justify-content: center; /* Center the form vertically */
}

.sticky-header img {
  height: auto;
  max-height: 60px; /* Reduce max height for better alignment */
  width: auto; /* Ensure the width adjusts automatically */
}

.header-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.header-content-left {
  flex: 1; /* Push the left content to the far left */
  display: flex;
  align-items: center;
  padding-left: 20px; /* Add left padding */
}

.header-content-left img {
  margin: 10px 0; /* Add top and bottom margin */
}

.header-content-center {
  flex: 2; /* Allow center content to grow twice as much */
  display: flex;
  justify-content: center; /* Center the text horizontally */
  margin-top: 10px; /* Add top margin */
}

.header-content-right {
  flex: 1; /* Push the left content to the far left */
}

.title {
  font-weight: bold;
  font-size: 3.5vw;
  margin: 0;
  position: relative;
  letter-spacing: 0.1em;
  color: #e43838;
}

.drop {
  width: .2em; height: .2em;
  border-radius: 0 100% 100% 100%;
  background-color: currentColor;
  position: absolute;
  top: 72%;
  animation: drop 3s infinite both;
}

.drop:nth-child(1) {
  left: 14%;
}

.drop:nth-child(2) {
  left: 39%;
  animation-delay: -.4s;
}

.drop:nth-child(3) {
  left: 55%;
  animation-delay: -1.5s;
}

.drop:nth-child(4) {
  left: 82%;
  animation-delay: -.8s;
}

.drop:nth-child(5) {
  left: 95.5%;
  animation-delay: -1.3s;
}

@keyframes drop {
  0% {
    transform: translateY(0) scaleX(.85) rotate(45deg);
    animation-timing-function: ease-out;
  }
  60% {
    transform: translateY(120%) scaleX(.85) rotate(45deg);
    animation-timing-function: ease-in;
  }
  80%, 100% {
    transform: translateY(60vh) scaleX(.85) rotate(45deg);
  }
}

@media (max-width: 1024px) {
  .form-container {
    padding-top: 60px; /* Ensure there's space for the sticky header on tablets */
    padding-left: 20px; /* Add padding on the left for tablets */
    padding-right: 20px; /* Add padding on the right for tablets */
  }

  form {
    width: 90%; /* Reduce width on tablets */
    margin: 0 auto; /* Center the form */
  }

  .header-content-right img {
    display: none; /* Hide the logo on mobile */
  }

  .header-content {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .title {
    font-weight: bold;
    font-size: 4vw;
    margin: 0;
    position: relative;
    letter-spacing: 0.1em;
    color: #e43838;
  }

  .header-content-left {
    flex: 1; /* Push the left content to the far left */
    display: flex;
    align-items: center;
    padding-left: 20px; /* Add left padding */
  }
  
  .header-content-left img {
    margin: 20px 0; /* Add top and bottom margin */
  }

  .header-content-center {
    margin-top: 15px; /* Add top and bottom margin */
  }
}

@media (max-width: 600px) {
  .sticky-header {
    height: 120px; /* Adjusted height for smaller screens */
  }

  .header-text {
    font-size: 18px;
  }

  .header-content-left img {
    display: none; /* Hide the logo on mobile */
  }

  .header-content-right img {
    display: none; /* Hide the logo on mobile */
  }

  .header-content {
    display: block;
    margin-top: 2%;
    margin-bottom: 4%;
  }

  .title {
    font-weight: bold;
    font-size: 7.5vw;
    margin: 0;
    position: relative;
    letter-spacing: 0.1em;
    color: #e43838;
  }
}

@media (max-width: 600px) {
  .form-container {
    padding-top: 0px; /* Ensure there's space for the sticky header on small screens */
    padding-left: 0; /* Remove left padding on mobile */
    padding-right: 0; /* Remove right padding on mobile */
  }

  .content {
    padding-top: 60px; /* Ensure there's space for the sticky header on small screens */
    background-color: white; /* Set container background to white */
    justify-content: flex-start; /* Align items at the top */
  }

  form {
    width: 100%;
    max-width: none;
    margin: 0; /* Remove margin on mobile */
  }

  .sticky-header {
    height: 120px; /* Adjusted height for smaller screens */
  }

  .header-text {
    font-size: 18px;
  }

  .header-content-left img {
    display: none; /* Hide the logo on mobile */
  }

  .header-content-right img {
    display: none; /* Hide the logo on mobile */
  }

  .header-content {
    display: block;
  }

  .title {
    font-weight: bold;
    font-size: 7.5vw;
    margin: 0;
    position: relative;
    letter-spacing: 0.1em;
    color: #e43838;
  }
}

.footer {
  background-color: #e43838;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  height: 60px; /* Adjust the height if necessary */
}

.footer-text {
  text-align: left; /* Align the text to the left */
  flex: 1; /* Allow the text to take up space */
  display: flex;
  align-items: center; /* Center text vertically */
  z-index: 2; /* Ensure the text is above other elements */
}

.footer a {
  color: white; /* Set the text color to white */
  text-decoration: underline; /* Add underline */
}

.footer a:visited {
  color: white; /* Ensure the color stays white when visited */
}

.footer a:hover {
  color: white; /* Keep the hover color white */
}

.footer a:active {
  color: white; /* Ensure the active color stays white */
}

.footer-icons {
  display: flex;
  justify-content: center; /* Center the icons horizontally */
  align-items: center; /* Center the icons vertically */
  flex: 1; /* Allow the icons to take up space */
  margin-left: -20%; /* Adjust to center the icons */
  z-index: 1; /* Ensure the icons are below the text */
}

.footer-icons img {
  width: 32px;
  height: 32px;
  margin: 0 10px;
}

.footer-icons-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 5.1; /* Allow the center content to grow twice as much */
  z-index: 1; /* Ensure the center content is below the text */
}
